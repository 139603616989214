/*
 * ⚠ This file is autogenerated ⚠
 */

export type SpacerSize = number;

export interface SpacerSizes {

  '5xl': SpacerSize;

  '4xl': SpacerSize;

  '3xl': SpacerSize;

  '2xl': SpacerSize;

  'xl': SpacerSize;

  'l': SpacerSize;

  's': SpacerSize;

  'xs': SpacerSize;

  '2xs': SpacerSize;

  '3xs': SpacerSize;

  '4xs': SpacerSize;

  '5xs': SpacerSize;

}

export const spacerSizes: SpacerSizes = {
  '5xs': 2,
  '4xs': 4,
  '3xs': 8,
  '2xs': 12,
  xs: 16,
  s: 20,
  l: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 56,
  '4xl': 80,
  '5xl': 160,
};
