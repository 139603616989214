/*
 * ⚠ This file is autogenerated ⚠
 */

export const badgeVariants = [ 'Critical', 'CriticalInverted', 'Informative', 'InformativeInverted', 'Neutral', 'NeutralInverted', 'NeutralInvertedTransparent', 'NeutralTransparent', 'PrimaryBlue', 'PrimaryBlueInverted', 'PrimaryYellow', 'PrimaryYellowInverted', 'Success', 'SuccessInverted', 'Tag', 'Warning', 'WarningInverted' ] as const;

export type BadgeVariant = typeof badgeVariants[number];

export const badgeSizes = [ '24' ] as const;

export type BadgeSize = typeof badgeSizes[number];
