/*
 * ⚠ This file is autogenerated ⚠
 */

export type IncrementSize = number;

export interface IncrementSizes {

  '12': IncrementSize;

  '16': IncrementSize;

  '20': IncrementSize;

  '24': IncrementSize;

  '28': IncrementSize;

  '36': IncrementSize;

  '40': IncrementSize;

  '48': IncrementSize;

  '64': IncrementSize;

  '100': IncrementSize;

  '200': IncrementSize;

  '420': IncrementSize;

  'sidebarwidth': IncrementSize;

  'formsmaxwidth': IncrementSize;

  'copymaxwidth': IncrementSize;

  'contentmaxwidth': IncrementSize;

  'fullcontentmaxwidth': IncrementSize;

}

export const incrementSizes: IncrementSizes = {
  12: 12,
  16: 16,
  20: 20,
  24: 24,
  28: 28,
  36: 36,
  40: 40,
  48: 48,
  64: 64,
  100: 100,
  200: 200,
  420: 420,
  fullcontentmaxwidth: 1280,
  contentmaxwidth: 960,
  copymaxwidth: 768,
  formsmaxwidth: 620,
  sidebarwidth: 300,
};
