/*
 * ⚠ This file is autogenerated ⚠
 */

export type BreakpointSize = number;

export interface BreakpointSizes {

  'desktopMargin': BreakpointSize;

  'desktopGutter': BreakpointSize;

  'laptopMargin': BreakpointSize;

  'laptopGutter': BreakpointSize;

  'tabletMargin': BreakpointSize;

  'tabletGutter': BreakpointSize;

  'mobileMargin': BreakpointSize;

  'mobileGutter': BreakpointSize;

  'brickMargin': BreakpointSize;

  'brickGutter': BreakpointSize;

}

export const breakpointSizes: BreakpointSizes = {
  brickGutter: 8,
  brickMargin: 8,
  mobileGutter: 16,
  mobileMargin: 16,
  tabletGutter: 16,
  tabletMargin: 40,
  laptopGutter: 24,
  laptopMargin: 40,
  desktopGutter: 24,
  desktopMargin: 160,
};
