/*
 * ⚠ This file is autogenerated ⚠
 */

export const iconButtonTypes = [ 'Floating', 'Heavy', 'Primary', 'Secondary', 'Subtle' ] as const;

export type IconButtonType = typeof iconButtonTypes[number];

export const iconButtonSizes = [ 'Average', 'Big', 'Huge', 'Large' ] as const;

export type IconButtonSize = typeof iconButtonSizes[number];
